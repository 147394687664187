import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-toolbar-oben',
  templateUrl: './menu-toolbar-oben.component.html',
  styleUrls: ['./menu-toolbar-oben.component.scss'],
})
export class MenuToolbarObenComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
