import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'iframe',
    loadChildren: () => import('./iframe/iframe.module').then( m => m.IframePageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then( m => m.VideoPageModule)
  },
  {
    path: 'neuanmelden',
    loadChildren: () => import('./neuanmelden/neuanmelden.module').then( m => m.NeuanmeldenPageModule)
  },
  {
    path: 'meintag',
    loadChildren: () => import('./meintag/meintag.module').then( m => m.MeintagPageModule)
  },
  {
    path: 'sportart',
    loadChildren: () => import('./sportart/sportart.module').then( m => m.SportartPageModule)
  },
  {
    path: 'kalenderblatt',
    loadChildren: () => import('./kalenderblatt/kalenderblatt.module').then( m => m.KalenderblattPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'zeitplan',
    loadChildren: () => import('./zeitplan/zeitplan.module').then( m => m.ZeitplanPageModule)
  },
  {
    path: 'modal-fehltag',
    loadChildren: () => import('./modal-fehltag/modal-fehltag.module').then( m => m.ModalFehltagPageModule)
  },
  {
    path: 'modal-wettkampf-neu',
    loadChildren: () => import('./modal-wettkampf-neu/modal-wettkampf-neu.module').then( m => m.ModalWettkampfNeuPageModule)
  },
  {
    path: 'planung',
    loadChildren: () => import('./planung/planung.module').then( m => m.PlanungPageModule)
  },
  {
    path: 'doku',
    loadChildren: () => import('./doku/doku.module').then( m => m.DokuPageModule)
  },
  {
    path: 'grunddaten',
    loadChildren: () => import('./grunddaten/grunddaten.module').then( m => m.GrunddatenPageModule)
  },
  {
    path: 'numberpicker',
    loadChildren: () => import('./numberpicker/numberpicker.module').then( m => m.NumberpickerPageModule)
  },
  {
    path: 'datepicker',
    loadChildren: () => import('./datepicker/datepicker.module').then( m => m.DatepickerPageModule)
  },
  {
    path: 'ernaehrung',
    loadChildren: () => import('./ernaehrung/ernaehrung.module').then( m => m.ErnaehrungPageModule)
  },
  {
    path: 'mails',
    loadChildren: () => import('./mails/mails.module').then( m => m.MailsPageModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./einstellungen/einstellungen.module').then( m => m.EinstellungenPageModule)
  },
  {
    path: 'mitgliedschaft',
    loadChildren: () => import('./mitgliedschaft/mitgliedschaft.module').then( m => m.MitgliedschaftPageModule)
  },
  {
    path: 'leistung',
    loadChildren: () => import('./leistung/leistung.module').then( m => m.LeistungPageModule)
  },
  {
    path: 'statistik',
    loadChildren: () => import('./statistik/statistik.module').then( m => m.StatistikPageModule)
  },
  {
    path: 'friends',
    loadChildren: () => import('./friends/friends.module').then( m => m.FriendsPageModule)
  },
  {
    path: 'friends-details',
    loadChildren: () => import('./friends-details/friends-details.module').then( m => m.FriendsDetailsPageModule)
  },
  {
    path: 'timeline',
    loadChildren: () => import('./timeline/timeline.module').then( m => m.TimelinePageModule)
  },
  {
    path: 'andere',
    loadChildren: () => import('./andere/andere.module').then( m => m.AnderePageModule)
  },
  {
    path: 'athletikeinheitneu',
    loadChildren: () => import('./athletikeinheitneu/athletikeinheitneu.module').then( m => m.AthletikeinheitneuPageModule)
  },
  {
    path: 'athletik',
    loadChildren: () => import('./athletik/athletik.module').then( m => m.AthletikPageModule)
  },
  {
    path: 'relax',
    loadChildren: () => import('./relax/relax.module').then( m => m.RelaxPageModule)
  },
  {
    path: 'individual',
    loadChildren: () => import('./individual/individual.module').then( m => m.IndividualPageModule)
  },
  {
    path: 'trainingseinheitneu',
    loadChildren: () => import('./trainingseinheitneu/trainingseinheitneu.module').then( m => m.TrainingseinheitneuPageModule)
  },
  {
    path: 'cardiorelaxeinheitneu',
    loadChildren: () => import('./cardiorelaxeinheitneu/cardiorelaxeinheitneu.module').then( m => m.CardiorelaxeinheitneuPageModule)
  },
  {
    path: 'athletikinfo',
    loadChildren: () => import('./athletikinfo/athletikinfo.module').then( m => m.AthletikinfoPageModule)
  },
  {
    path: 'trainingsablauf',
    loadChildren: () => import('./trainingsablauf/trainingsablauf.module').then( m => m.TrainingsablaufPageModule)
  },
  {
    path: 'hrv',
    loadChildren: () => import('./hrv/hrv.module').then( m => m.HrvPageModule)
  },
  {
    path: 'ruhepuls',
    loadChildren: () => import('./ruhepuls/ruhepuls.module').then( m => m.RuhepulsPageModule)
  },
  {
    path: 'akku',
    loadChildren: () => import('./akku/akku.module').then( m => m.AkkuPageModule)
  },
  {
    path: 'habits',
    loadChildren: () => import('./habits/habits.module').then( m => m.HabitsPageModule)
  },
  {
    path: 'motivation',
    loadChildren: () => import('./motivation/motivation.module').then( m => m.MotivationPageModule)
  },
  {
    path: 'modalkalender',
    loadChildren: () => import('./modalkalender/modalkalender.module').then( m => m.ModalkalenderPageModule)
  },
  {
    path: 'assistent',
    loadChildren: () => import('./assistent/assistent.module').then( m => m.AssistentPageModule)
  },
  {
    path: 'laktatdiagnostik-auswertung',
    loadChildren: () => import('./laktatdiagnostik-auswertung/laktatdiagnostik-auswertung.module').then( m => m.LaktatdiagnostikAuswertungPageModule)
  },
  {
    path: 'tagesablauf',
    loadChildren: () => import('./tagesablauf/tagesablauf.module').then( m => m.TagesablaufPageModule)
  },
  {
    path: 'fileupload',
    loadChildren: () => import('./fileupload/fileupload.module').then( m => m.FileuploadPageModule)
  },
  {
    path: 'passlost',
    loadChildren: () => import('./passlost/passlost.module').then( m => m.PasslostPageModule)
  },
  {
    path: 'ernaehrungsplan',
    loadChildren: () => import('./ernaehrungsplan/ernaehrungsplan.module').then( m => m.ErnaehrungsplanPageModule)
  },
  {
    path: 'rezeptdetails',
    loadChildren: () => import('./rezeptdetails/rezeptdetails.module').then( m => m.RezeptdetailsPageModule)
  },
  {
    path: 'rezeptalternative',
    loadChildren: () => import('./rezeptalternative/rezeptalternative.module').then( m => m.RezeptalternativePageModule)
  },
  {
    path: 'auswahl',
    loadChildren: () => import('./auswahl/auswahl.module').then( m => m.AuswahlPageModule)
  },
  {
    path: 'wissen',
    loadChildren: () => import('./wissen/wissen.module').then( m => m.WissenPageModule)
  },
  {
    path: 'stravaimport',
    loadChildren: () => import('./stravaimport/stravaimport.module').then( m => m.StravaimportPageModule)
  },
  {
    path: 'polarimport',
    loadChildren: () => import('./polarimport/polarimport.module').then( m => m.PolarimportPageModule)
  },
  {
    path: 'menueimport',
    loadChildren: () => import('./menueimport/menueimport.module').then( m => m.MenueimportPageModule)
  },
  {
    path: 'garminimport',
    loadChildren: () => import('./garminimport/garminimport.module').then( m => m.GarminimportPageModule)
  },
  {
    path: 'ernaehrung-rezepte',
    loadChildren: () => import('./ernaehrung-rezepte/ernaehrung-rezepte.module').then( m => m.ErnaehrungRezeptePageModule)
  },
  {
    path: 'lebensmittelneu',
    loadChildren: () => import('./lebensmittelneu/lebensmittelneu.module').then( m => m.LebensmittelneuPageModule)
  },
  {
    path: 'wochenplan',
    loadChildren: () => import('./wochenplan/wochenplan.module').then( m => m.WochenplanPageModule)
  },
  {
    path: 'jubel',
    loadChildren: () => import('./jubel/jubel.module').then( m => m.JubelPageModule)
  },
  {
    path: 'challenges',
    loadChildren: () => import('./challenges/challenges.module').then( m => m.ChallengesPageModule)
  },
  {
    path: 'community',
    loadChildren: () => import('./community/community.module').then( m => m.CommunityPageModule)
  },
  {
    path: 'fixplan',
    loadChildren: () => import('./fixplan/fixplan.module').then( m => m.FixplanPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
